import React, { Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'

import { AppWrapper, PageContent } from '@procsea/design-system'

import PageLoader from 'src/components/base/loader/PageLoader'
import NotFound from 'src/components/base/notFound/NotFound'
import { MembershipProvider } from 'src/contexts/MembershipContext'
import { useConnectedBuyerMembership, useCurrentMembership } from 'src/contexts/userContext'
import useOrganisationPusherSubscriptions from 'src/hooks/useOrganisationPusherSubscription'

import { useInitializeBuyerChat } from '../chat/chat-buyer/useInitializeBuyerChat'
import { useInitializeSellerChat } from '../chat/chat-seller/useInitializeSellerChat'
import MainMenu from '../navigation/MainMenu/MainMenu'

const AsyncSellerMarketplace = lazy(
  () => import('src/components/presentationals/seller-dashboard/SellerMarketplace')
)

const AsyncSellerSection = lazy(
  () => import('src/components/presentationals/seller-dashboard/SellerSection')
)

const SellerDashboard = () => {
  const currentMembership = useCurrentMembership()
  const buyerMembership = useConnectedBuyerMembership()

  useOrganisationPusherSubscriptions()

  useInitializeSellerChat({ contextualMembership: currentMembership })

  useInitializeBuyerChat({ contextualMembership: buyerMembership })

  return (
    <AppWrapper>
      <MainMenu />
      <PageContent>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            {buyerMembership && (
              <Route path="/:locale/:userType(seller)/marketplace/:marketplaceId">
                <MembershipProvider membership={buyerMembership}>
                  <AsyncSellerMarketplace />
                </MembershipProvider>
              </Route>
            )}

            <Route path="/:locale/:userType(seller)/">
              <AsyncSellerSection />
            </Route>

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Suspense>
      </PageContent>
    </AppWrapper>
  )
}

export default SellerDashboard
