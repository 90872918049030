import { useQueryClient } from '@tanstack/react-query'
import { Client, Conversation } from '@twilio/conversations'
import { useEffect, useState } from 'react'

import { useSellerChatTokenQuery } from 'src/queries/shared'
import { Membership } from 'src/types'

import { useChatActions, useChatConversationClient } from './useSellerChatStore'

interface UseSellerChatArgs {
  contextualMembership: Membership
}

export const useInitializeSellerChat = ({ contextualMembership }: UseSellerChatArgs) => {
  const queryClient = useQueryClient()

  const [tokenAboutToExpire, setTokenAboutToExpire] = useState(false)

  const { data: chatToken } = useSellerChatTokenQuery({
    contextualMembershipId: contextualMembership.id,
    queryOptions: { enabled: contextualMembership.optChatFeature },
  })

  const {
    addConversation,
    setConversations,
    setIsConversationsLoading,
    removeConversation,
    updateConversation,
    setConversationClient,
  } = useChatActions()

  const clientConversation = useChatConversationClient()

  useEffect(() => {
    if (contextualMembership.optChatFeature && !clientConversation && chatToken?.token) {
      const client = new Client(chatToken.token)

      setConversationClient(client)
    }
  }, [contextualMembership.optChatFeature, clientConversation, chatToken?.token])

  const getChannels = async () => {
    if (clientConversation) {
      setIsConversationsLoading(true)

      const paginatedConversations = await clientConversation.getSubscribedConversations()

      setIsConversationsLoading(false)
      setConversations(paginatedConversations?.items)
    }
  }

  const handleConversationAdded = (conversation: Conversation) => {
    addConversation(conversation)
  }

  const handleConversationUpdated = ({ conversation }: { conversation: Conversation }) => {
    updateConversation(conversation)
  }

  const handleConversationRemoved = (conversation: Conversation) => {
    removeConversation(conversation.sid)
  }

  const handleTokenRefresh = () => {
    queryClient.invalidateQueries({ queryKey: ['chatToken'], refetchType: 'active' })
    setTokenAboutToExpire(true)
  }

  useEffect(() => {
    if (tokenAboutToExpire && clientConversation && chatToken?.token) {
      clientConversation.updateToken(chatToken.token)
      setTokenAboutToExpire(false)
    }
  }, [chatToken?.token])

  useEffect(() => {
    if (clientConversation) {
      getChannels().then(() => {
        clientConversation.on('conversationAdded', handleConversationAdded)
        clientConversation.on('conversationRemoved', handleConversationRemoved)
        clientConversation.on('conversationUpdated', handleConversationUpdated)
        clientConversation.on('tokenAboutToExpire', handleTokenRefresh)
      })
    }

    return () => {
      if (clientConversation) {
        clientConversation.removeAllListeners()
      }
    }
  }, [clientConversation])
}
